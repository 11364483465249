#upcoming-elections-wrapper {
    max-width: 1000px;
    margin: 2em auto;
    width: 85%;
    font-family: Roboto, serif;
    line-height: 1.5;
    background: rgba(249, 247, 247, 0.9);
    box-shadow: 20px 25px 13px -20px rgba(0,0,0,0.35);
    border-radius: 9px;
    overflow-wrap: break-word;
}

.upcoming-election-container {
    padding: 1em;
}

#upcoming-elections-title {
    font-size: 1.5em;
    padding: 1em;
}

.upcoming-election-name {
    font-weight: bold;
    font-size: 1.0em;
}

.upcoming-election-day {
    font-size: .85em;
}