@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100vw;

  -ms-overflow-style: none;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*.content {*/
/*  min-height: calc(100vh - 150px);*/
/*}*/

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.address-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 5em;
}

.address-form {
    width: 85%;
    font-family: Roboto, serif;
    background: rgba(249, 247, 247, 0.9);
    box-shadow: 20px 25px 13px -20px rgba(0,0,0,0.35);
    border-radius: 9px;
    text-align: center;
    padding-top: 2em;
}

#error-response {
    color: #C44536;
    font-size: 10px;
}

.form-intro {
    width: 85%;
    max-width: 1000px;
    line-height: 1.5em;
    text-align: center;
    margin: auto;
    padding-top: 5%;
    padding-bottom: 3%;
}

.rep-search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1em;
}

.address-form-field {
    font-weight: 400;
    font-family: Roboto, serif;
    color: #5c5c5c;
    width: 85%;
    padding: 12px 20px;
    margin: 8px auto;
    border: 1px solid #ffffff;
    border-radius: 4px;
    background: #ffffff;
}

.address-form-rep-button, .address-form-poll-button {
    color: #ffffff;
    font-family: Roboto, serif;
    background-color: #197278;
    border: 1px solid #197278;
    border-radius: 5px;
    width: 45%;
    height: 15%;
    margin: 10px 5px 10px 5px;
    padding: 10px 10px;
    text-align: center;
    font-size: 1em;
    font-weight: 500;
    letter-spacing: 1.4px;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
}

.address-form-poll-button {
    color: #197278;
    background-color: rgba(249, 247, 247, 0.9);
    border: 2px solid #197278;
}

.address-form-rep-button:hover {
    cursor: pointer;
    opacity: .80;
}

.address-form-poll-button:hover {
    cursor: pointer;
    background-color: #c8c8c8;
}

#home-form-btn-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media(min-width: 375px) {
    .address-form-title h1 {
        font-size: 1.5em;
    }

    .address-form-field {
        width: 75%;
    }
}

@media(min-width: 425px) {
    .address-form-title h1 {
        font-size: 1.3em;
    }
}

@media(min-width: 768px) and (max-width: 1023px) {
    .address-form {
        width: 75%;
    }

    .address-form-title h1 {
        font-size: 2.5em;
        margin-bottom: 1px;
    }

    .error-response {
        font-size: 12px;
    }

    .address-form-field {
        font-size: 15px;
    }

    #home-form-btn-container {
        flex-direction: row;
        justify-content: space-evenly;
        width: 60%;
    }
}

@media(min-width: 1024px) {
    .address-form {
        max-width: 800px;
    }

    .address-form-title h1 {
        font-size: 2.5em;
    }

    .error-response {
        font-size: 13px;
    }

    .address-form-field {
        font-weight: 400;
        font-size: .9em;
    }

    .address-form-rep-button, .address-form-poll-button {
        width: 150px;
    }

    #home-form-btn-container {
        flex-direction: row;
        justify-content: space-evenly;
        width: 60%;
    }
}

.content-block-1 {
    width: 100%;
    margin: 0;
    background-color: #f2f2f2;
}

.content-block-1 img {
    height: 200px;
}

.home-h1 {
    background-color: #f2f2f2;
    font-family: Roboto, sans-serif;
    width: 100%;
    text-align: center;
    padding-top: 1vh;
    padding-bottom: 1%;
    color: #E09F3E;
    font-size: 3.5em;
}

.home-intro {
    color: #5c5c5c;
    width: 85%;
    max-width: 1000px;
    margin: auto;
    line-height: 1.5em;
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
    font-size: 1.25em;
    font-family: Roboto, sans-serif;
}

.intro-block {
    padding: 2em 0;
    box-shadow: inset 0 4px 6px 0 rgba(26, 26, 26, 0.15), inset 0 -4px 6px 0 rgba(26, 26, 26, 0.15);
}

.content-block-2 {
    width: 100%;
    background-color: #E8B973;
}

.block2-h1 {
    width: 85%;
    max-width: 1000px;
    margin: auto;
    line-height: 1.5em;
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 1%;
    color: #f2f2f2;
    font-family: Roboto, sans-serif;
    font-size: 3em;
}

.upcoming {
    font-style: italic;
    padding-bottom: 3%;
    color: #5c5c5c;
    font-size: smaller;
    max-width: 1000px;
    margin: 0 auto;
}

.upcoming p {
    width: 75%;
    margin: 0 auto;
}

#upcoming-details {
    margin-top: 1em;
}

.officials-search-results-wrapper, .office-search-results-wrapper {
    max-width: 1000px;
    margin: 2em auto;
    width: 85%;
    font-family: Roboto, serif;
    background: rgba(249, 247, 247, 0.9);
    box-shadow: 20px 25px 13px -20px rgba(0,0,0,0.35);
    border-radius: 9px;
    padding-top: 2em;
    overflow-wrap: break-word;
}

.office-search-results-wrapper {
    max-width: 800px;
}

.officials-intro {
    font-size: 1.15em;
    color: #5c5c5c;
    padding-bottom: 0.5em;
}

.user-city {
    font-size: 1.5em;
    color: #5c5c5c;
    padding-bottom: 1em;
}

.elected-offices-wrapper, .elected-officials-wrapper {
    line-height: 1.5;
    padding-bottom: 1em;
}

#office-name {
    font-size: 1.5em;
    padding-bottom: 1em;
}

.elected-office {
    cursor: pointer;
    color: #197278;
}

.elected-office-container, .elected-official-container {
    padding-bottom: 10px;
    text-align: center;
}

.elected-office:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

.elected-official {
    cursor: pointer;
    color: #197278;
    line-height: 1.5em;
}

.elected-official:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

.official-details-wrapper {
    max-width: 1200px;
    width: 80%;
    margin: 2em auto;
    padding: 20px 0;
    font-family: Roboto, serif;
    background: rgba(249, 247, 247, 0.9);
    color: #5c5c5c;
    box-shadow: 20px 25px 13px -20px rgba(0,0,0,0.35);
    border-radius: 9px;
    overflow-wrap: break-word;
}

.official-name-container {
    padding-bottom: 1em;
}

#official-name {
    font-size: 1.5em;
    font-weight: 800;
    color: #E09F3E;
}

#official-party {
    font-size: 1.25em;
}

.official-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.official-details {
    line-height: 1.5em;
    text-align: left;
    padding-left: 7%;
    align-self: center;
}

#official-address {
    text-decoration: none;
    color: #5c5c5c;
    padding-top: 2%;
    padding-bottom: 1%;
}

#official-phone a {
    text-decoration: none;
    color: #197278;
    padding-top: 1%;
    padding-bottom: 1%;
}

#official-phone a:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

#official-email {
    text-decoration: none;
    color: #197278;
    padding-top: 1%;
    padding-bottom: 1%;
}

#official-email a:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

#official-website {
    text-decoration: none;
    color: #197278;
    padding-top: 1%;
    padding-bottom: 1%;
}

#official-website:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

#official-image, #official-placeholder-image {
    height: 20em;
    border-radius: 9px;
}

#official-placeholder-image {
    opacity: .5;
}

#officials-icon {
    max-width: 20px;
    max-height: 20px;
    padding-right: 3px;
    margin-right: 8px;
}

.details-social {
    padding-top: 1%;
    padding-bottom: 1%;
}

.official-social {
    text-decoration: none;
    color: #197278;
}

.official-social:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

@media(min-width: 768px) and (max-width: 1023px) {
    .official-details-wrapper {
        width: 90%;
    }

    .official-details-container {
        flex-direction: row;
    }

    .official-details {
        max-width: 50%;
    }
}

@media(min-width: 1024px) {
    .officials-search-results-wrapper {
        max-width: 900px;
    }

    .official-details-wrapper {
        max-width: 900px;
    }

    .official-details-container {
        flex-direction: row;
    }

    .official-details {
        max-width: 50%;
    }
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#about-content {
    width: 95%;
    max-width: 1500px;
    margin: 10vh auto;
}

#statement-of-purpose {
    font-size: 1.5em;
    max-width: 1000px;
    margin: 0 auto;
    color: rgb(107, 98, 98);
}

.team-members {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2em 2em 0 2em;
}

.member-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;
    padding: 3em 0;
}

.member-2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;
    padding: 3em 0;
}

/* TEAM MEMBER CONTENT ITEMS */
.member-photo {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    margin-top: 10px;
    margin-right: 13px;
    margin-bottom: 10px;
    margin-left: 0;
}

.member-content {
    display: inline;
}

h2.member-name {
    color: #E09F3E;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    padding-top: 4px;
    margin-bottom: 4px;
}

h3.member-title {
    color: #A9A9A9;
    font-style: italic;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 6px;
}

p.about-member {
    color: rgb(107, 98, 98);
    text-align: left;
    margin-top: 4px;
    margin-bottom: 18px;
    font-size: 1em;
    line-height: 1.35em;
}

.social-links {
    color: #353535;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    text-decoration: none;
    margin-bottom: 13px;
}

.social-links:hover {
    cursor: pointer;
    text-decoration: none;
}

.social-links:visited {
    color: #353535;
    text-decoration: none;
}

.github, .github > a, .github:hover {
    text-decoration: none;
}

.icon-social-github {
    color: #265443;
    text-decoration: none;
    margin-left: 18px;
    margin-right: 18px;
}

.icon-social-github:hover {
    color: #5D9F9E;
}

.linkedin, .linkedin > a, .linkedin:hover {
    text-decoration: none;
}

.icon-social-linkedin {
    color: #265443;
    text-decoration: none;
    margin-left: 18px;
    margin-right: 18px;
}

.icon-social-linkedin:hover {
    color: #5D9F9E;
}

.portfolio, .portfolio > a, .portfolio:hover {
    text-decoration: none;
}

.icon-globe {
    color: #265443;
    text-decoration: none;
    margin-left: 18px;
    margin-right: 18px;
}

.icon-globe:hover {
    color: #5D9F9E;
}

@media (min-width: 768px) and (max-width: 1023px){
    .team-members{
        display: grid;
        grid-template-columns: repeat(2,.5fr);
        grid-template-rows: repeat(1,.5fr);
        margin: 15px;
        padding-top: 5em;
    }

    .member-photo {
        display: flex;
        margin-left: 50px;
    }

    h2.member-name {
        font-size: 25px;
        margin-left: 23px;
    }

    h3.member-title {
        font-size: 20px;
        margin-left: 23px;
    }

    p.about-member {
        margin: 20px;
        font-size: 1em;
        line-height: 1.35em;
    }

    .icon-social-github {
        margin-left: 50px;
        margin-right: 19px;
        font-size: 20px;
    }

    .icon-social-linkedin {
        margin-left: 4px;
        margin-right: 19px;
        font-size: 20px;
    }

    .icon-globe {
        margin-left: 5px;
        margin-right: 19px;
        font-size: 20px;
    }
}

@media (min-width: 769px){
    .team-members{
        display: grid;
        grid-template-columns: repeat(2,.5fr);
        grid-template-rows: repeat(1,.5fr);
        margin-left: 40px;
        padding-top: 5em;
    }

    .member-photo {
        display: flex;
        width: 80px;
        height: 80px;
        margin-left: 50px;
    }

    h2.member-name {
        font-size: 25px;
        margin-left: 12px;
        margin-bottom: 10px;
    }

    h3.member-title {
        font-size: 22px;
        margin-left: 12px;
    }

    p.about-member {
        color: rgb(107, 98, 98);
        margin: 20px;
        margin-left: 50px;
        margin-right: 100px;
        font-size: 1em;
        line-height: 1.35em;
    }

    .icon-social-github {
        margin-left: 5px;
        margin-right: 4px;
        font-size: 30px;
    }

    .icon-social-linkedin {
        margin-left: 50px;
        margin-right: 4px;
        font-size: 30px;
    }

    .icon-globe {
        margin-left: 5px;
        margin-right: 4px;
        font-size: 30px;
    }
}
.election-container, .resources-container {
    max-width: 1000px;
    margin: 2em auto;
    width: 85%;
    font-family: Roboto, serif;
    line-height: 1.5;
    background: rgba(249, 247, 247, 0.9);
    box-shadow: 20px 25px 13px -20px rgba(0,0,0,0.35);
    border-radius: 9px;
    padding-bottom: 1em;
    overflow-wrap: break-word;
}

.election-user-address {
    font-weight: bold;
    font-size: 1.25em;
    padding: 1em;
}

.election-day {
    padding-bottom: 1em;
}

.voting-sites-link, .resources-link {
    color: #197278;
    text-decoration: none;
}

.voting-sites-link:hover, .resources-link:hover {
    color: #5D9F9E;
    border-bottom: solid 1px #E09F3E;
    cursor: pointer;
}

.polling-locations-title, #early-voting-sites-title, .resources-title {
    font-weight: bold;
    font-size: 1.5em;
    padding: 1em;
}

.early-vote-locations-wrapper {
    padding-bottom: 1.5em;
}

.early-vote-location-hours {
    padding-bottom: 2em;
}

.early-vote-location-dates {
    padding-top: .5em;
    font-style: italic;
}

.polling-location-details {
    padding: 1em;
}

.site-name {
    font-weight: bold;
    font-size: 1.15em;
}

#officials-icon {
    max-width: 20px;
    max-height: 20px;
    padding-right: 3px;
    margin-right: 8px;
}

.local-resources-title, .additional-resources-title {
    font-weight: bold;
    font-size: 1.15em;
    padding: 1em;
}

.resources-wrapper {
    line-height: 1.5;
    max-width: 90%;
    margin: 0 auto;
}

.resources-subtitle {
    padding-top: .75em;
}

#upcoming-elections-wrapper {
    max-width: 1000px;
    margin: 2em auto;
    width: 85%;
    font-family: Roboto, serif;
    line-height: 1.5;
    background: rgba(249, 247, 247, 0.9);
    box-shadow: 20px 25px 13px -20px rgba(0,0,0,0.35);
    border-radius: 9px;
    overflow-wrap: break-word;
}

.upcoming-election-container {
    padding: 1em;
}

#upcoming-elections-title {
    font-size: 1.5em;
    padding: 1em;
}

.upcoming-election-name {
    font-weight: bold;
    font-size: 1.0em;
}

.upcoming-election-day {
    font-size: .85em;
}
.contact-form {
    height: 100%;
    margin: auto;
    padding: 0px;
}

.form-header {
    color: #E09F3E;
    width: 85%;
    max-width: 1000px;
    margin: auto;
    text-align: center;
    padding-top: 4%;
    padding-bottom: 0;
    line-height: 1.5em;
}

#my-form {
    font-family: Roboto, serif;
    width: 95%;
    margin: 1.5em auto 0 auto;
    padding-bottom: 40px;
}
#my-form input, textarea {
    font-family: Roboto, serif;
    color: #5c5c5c;
    background: rgba(249, 247, 247, 0.9);
    box-shadow: 20px 25px 13px -20px rgba(0,0,0,0.35);
    border: 0;
    box-sizing: border-box;
    display: block;
    font-size: 12pt;
    margin-bottom: 3px;
    outline: none;
    padding: 13px 15px;
    width: 100%;
}

#my-form textarea {
    height: 200px;
    resize: none;
}

#contact-button {
    color:#ffffff;
    font-family: Roboto, serif;
    border: none;
    border-radius: 5px;
    width: 25%;
    height: 15%;
    margin: 15px 5px 10px 5px;
    background-color: #E09F3E;
    padding: 10px 10px;
    text-align: center;
    font-size: 1em;
    font-weight: 500;
    letter-spacing: 1.4px;
    float: right;
}

#contact-button:hover {
    cursor: pointer;
    opacity: .90;
}

#my-form-status {
    font-size: 1em;
    margin: 1em auto;
}

@media (min-width: 767px) and (max-width: 1023px) {
    #my-form {
        width: 70%;
    }
    #my-form textarea {
        height: 300px;
    }
}

@media (min-width: 1024px) {
    #my-form {
        width: 50%;
        margin: 5vh auto 0 auto;
    }
    #my-form textarea {
        height: 300px;
    }
}
nav {
    display: flex;
    justify-content: flex-start;
    position:relative;
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.4), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    background: transparent;
}

nav div {
    padding: 1em 1em;
    font-size: 1em;
    color: #5E5D5D;
}

nav span:hover, nav span:visited {
    color: #5E5D5D;
    border-bottom: 2px solid #E09F3E;;
}

.footer {
    margin: 25px 10px 10px 10px;
    flex-shrink: 0;
    bottom: 0;
    position: relative;
    text-align: center;
}

.footer a {
    text-decoration: none;
}

@media(max-width: 768px) {
    nav div {
        padding: .5em .5em;
        font-size: 1em;
        color: #5E5D5D;
    }
}

