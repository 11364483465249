.election-container, .resources-container {
    max-width: 1000px;
    margin: 2em auto;
    width: 85%;
    font-family: Roboto, serif;
    line-height: 1.5;
    background: rgba(249, 247, 247, 0.9);
    box-shadow: 20px 25px 13px -20px rgba(0,0,0,0.35);
    border-radius: 9px;
    padding-bottom: 1em;
    overflow-wrap: break-word;
}

.election-user-address {
    font-weight: bold;
    font-size: 1.25em;
    padding: 1em;
}

.election-day {
    padding-bottom: 1em;
}

.voting-sites-link, .resources-link {
    color: #197278;
    text-decoration: none;
}

.voting-sites-link:hover, .resources-link:hover {
    color: #5D9F9E;
    border-bottom: solid 1px #E09F3E;
    cursor: pointer;
}

.polling-locations-title, #early-voting-sites-title, .resources-title {
    font-weight: bold;
    font-size: 1.5em;
    padding: 1em;
}

.early-vote-locations-wrapper {
    padding-bottom: 1.5em;
}

.early-vote-location-hours {
    padding-bottom: 2em;
}

.early-vote-location-dates {
    padding-top: .5em;
    font-style: italic;
}

.polling-location-details {
    padding: 1em;
}

.site-name {
    font-weight: bold;
    font-size: 1.15em;
}

#officials-icon {
    max-width: 20px;
    max-height: 20px;
    padding-right: 3px;
    margin-right: 8px;
}

.local-resources-title, .additional-resources-title {
    font-weight: bold;
    font-size: 1.15em;
    padding: 1em;
}

.resources-wrapper {
    line-height: 1.5;
    max-width: 90%;
    margin: 0 auto;
}

.resources-subtitle {
    padding-top: .75em;
}
