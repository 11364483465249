nav {
    display: flex;
    justify-content: flex-start;
    position:relative;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.4), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.4), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.4), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    background: transparent;
}

nav div {
    padding: 1em 1em;
    font-size: 1em;
    color: #5E5D5D;
}

nav span:hover, nav span:visited {
    color: #5E5D5D;
    border-bottom: 2px solid #E09F3E;;
}

.footer {
    margin: 25px 10px 10px 10px;
    flex-shrink: 0;
    bottom: 0;
    position: relative;
    text-align: center;
}

.footer a {
    text-decoration: none;
}

@media(max-width: 768px) {
    nav div {
        padding: .5em .5em;
        font-size: 1em;
        color: #5E5D5D;
    }
}
