.content-block-1 {
    width: 100%;
    margin: 0;
    background-color: #f2f2f2;
}

.content-block-1 img {
    height: 200px;
}

.home-h1 {
    background-color: #f2f2f2;
    font-family: Roboto, sans-serif;
    width: 100%;
    text-align: center;
    padding-top: 1vh;
    padding-bottom: 1%;
    color: #E09F3E;
    font-size: 3.5em;
}

.home-intro {
    color: #5c5c5c;
    width: 85%;
    max-width: 1000px;
    margin: auto;
    line-height: 1.5em;
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
    font-size: 1.25em;
    font-family: Roboto, sans-serif;
}

.intro-block {
    padding: 2em 0;
    box-shadow: inset 0 4px 6px 0 rgba(26, 26, 26, 0.15), inset 0 -4px 6px 0 rgba(26, 26, 26, 0.15);
}

.content-block-2 {
    width: 100%;
    background-color: #E8B973;
}

.block2-h1 {
    width: 85%;
    max-width: 1000px;
    margin: auto;
    line-height: 1.5em;
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 1%;
    color: #f2f2f2;
    font-family: Roboto, sans-serif;
    font-size: 3em;
}

.upcoming {
    font-style: italic;
    padding-bottom: 3%;
    color: #5c5c5c;
    font-size: smaller;
    max-width: 1000px;
    margin: 0 auto;
}

.upcoming p {
    width: 75%;
    margin: 0 auto;
}

#upcoming-details {
    margin-top: 1em;
}
