.officials-search-results-wrapper, .office-search-results-wrapper {
    max-width: 1000px;
    margin: 2em auto;
    width: 85%;
    font-family: Roboto, serif;
    background: rgba(249, 247, 247, 0.9);
    box-shadow: 20px 25px 13px -20px rgba(0,0,0,0.35);
    border-radius: 9px;
    padding-top: 2em;
    overflow-wrap: break-word;
}

.office-search-results-wrapper {
    max-width: 800px;
}

.officials-intro {
    font-size: 1.15em;
    color: #5c5c5c;
    padding-bottom: 0.5em;
}

.user-city {
    font-size: 1.5em;
    color: #5c5c5c;
    padding-bottom: 1em;
}

.elected-offices-wrapper, .elected-officials-wrapper {
    line-height: 1.5;
    padding-bottom: 1em;
}

#office-name {
    font-size: 1.5em;
    padding-bottom: 1em;
}

.elected-office {
    cursor: pointer;
    color: #197278;
}

.elected-office-container, .elected-official-container {
    padding-bottom: 10px;
    text-align: center;
}

.elected-office:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

.elected-official {
    cursor: pointer;
    color: #197278;
    line-height: 1.5em;
}

.elected-official:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

.official-details-wrapper {
    max-width: 1200px;
    width: 80%;
    margin: 2em auto;
    padding: 20px 0;
    font-family: Roboto, serif;
    background: rgba(249, 247, 247, 0.9);
    color: #5c5c5c;
    box-shadow: 20px 25px 13px -20px rgba(0,0,0,0.35);
    border-radius: 9px;
    overflow-wrap: break-word;
}

.official-name-container {
    padding-bottom: 1em;
}

#official-name {
    font-size: 1.5em;
    font-weight: 800;
    color: #E09F3E;
}

#official-party {
    font-size: 1.25em;
}

.official-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.official-details {
    line-height: 1.5em;
    text-align: left;
    padding-left: 7%;
    align-self: center;
}

#official-address {
    text-decoration: none;
    color: #5c5c5c;
    padding-top: 2%;
    padding-bottom: 1%;
}

#official-phone a {
    text-decoration: none;
    color: #197278;
    padding-top: 1%;
    padding-bottom: 1%;
}

#official-phone a:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

#official-email {
    text-decoration: none;
    color: #197278;
    padding-top: 1%;
    padding-bottom: 1%;
}

#official-email a:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

#official-website {
    text-decoration: none;
    color: #197278;
    padding-top: 1%;
    padding-bottom: 1%;
}

#official-website:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

#official-image, #official-placeholder-image {
    height: 20em;
    border-radius: 9px;
}

#official-placeholder-image {
    opacity: .5;
}

#officials-icon {
    max-width: 20px;
    max-height: 20px;
    padding-right: 3px;
    margin-right: 8px;
}

.details-social {
    padding-top: 1%;
    padding-bottom: 1%;
}

.official-social {
    text-decoration: none;
    color: #197278;
}

.official-social:hover {
    color: #5D9F9E;
    border-bottom: #E09F3E solid 1px;
}

@media(min-width: 768px) and (max-width: 1023px) {
    .official-details-wrapper {
        width: 90%;
    }

    .official-details-container {
        flex-direction: row;
    }

    .official-details {
        max-width: 50%;
    }
}

@media(min-width: 1024px) {
    .officials-search-results-wrapper {
        max-width: 900px;
    }

    .official-details-wrapper {
        max-width: 900px;
    }

    .official-details-container {
        flex-direction: row;
    }

    .official-details {
        max-width: 50%;
    }
}
