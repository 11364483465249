*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.address-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 5em;
}

.address-form {
    width: 85%;
    font-family: Roboto, serif;
    background: rgba(249, 247, 247, 0.9);
    box-shadow: 20px 25px 13px -20px rgba(0,0,0,0.35);
    border-radius: 9px;
    text-align: center;
    padding-top: 2em;
}

#error-response {
    color: #C44536;
    font-size: 10px;
}

.form-intro {
    width: 85%;
    max-width: 1000px;
    line-height: 1.5em;
    text-align: center;
    margin: auto;
    padding-top: 5%;
    padding-bottom: 3%;
}

.rep-search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1em;
}

.address-form-field {
    font-weight: 400;
    font-family: Roboto, serif;
    color: #5c5c5c;
    width: 85%;
    padding: 12px 20px;
    margin: 8px auto;
    border: 1px solid #ffffff;
    border-radius: 4px;
    background: #ffffff;
}

.address-form-rep-button, .address-form-poll-button {
    color: #ffffff;
    font-family: Roboto, serif;
    background-color: #197278;
    border: 1px solid #197278;
    border-radius: 5px;
    width: 45%;
    height: 15%;
    margin: 10px 5px 10px 5px;
    padding: 10px 10px;
    text-align: center;
    font-size: 1em;
    font-weight: 500;
    letter-spacing: 1.4px;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
}

.address-form-poll-button {
    color: #197278;
    background-color: rgba(249, 247, 247, 0.9);
    border: 2px solid #197278;
}

.address-form-rep-button:hover {
    cursor: pointer;
    opacity: .80;
}

.address-form-poll-button:hover {
    cursor: pointer;
    background-color: #c8c8c8;
}

#home-form-btn-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media(min-width: 375px) {
    .address-form-title h1 {
        font-size: 1.5em;
    }

    .address-form-field {
        width: 75%;
    }
}

@media(min-width: 425px) {
    .address-form-title h1 {
        font-size: 1.3em;
    }
}

@media(min-width: 768px) and (max-width: 1023px) {
    .address-form {
        width: 75%;
    }

    .address-form-title h1 {
        font-size: 2.5em;
        margin-bottom: 1px;
    }

    .error-response {
        font-size: 12px;
    }

    .address-form-field {
        font-size: 15px;
    }

    #home-form-btn-container {
        flex-direction: row;
        justify-content: space-evenly;
        width: 60%;
    }
}

@media(min-width: 1024px) {
    .address-form {
        max-width: 800px;
    }

    .address-form-title h1 {
        font-size: 2.5em;
    }

    .error-response {
        font-size: 13px;
    }

    .address-form-field {
        font-weight: 400;
        font-size: .9em;
    }

    .address-form-rep-button, .address-form-poll-button {
        width: 150px;
    }

    #home-form-btn-container {
        flex-direction: row;
        justify-content: space-evenly;
        width: 60%;
    }
}
